import React, { useEffect } from 'react';

import { PageProps } from '../context/PageData';

import getPageBySlug from '../lib/contentful/getPageBySlug';
import { trackRumEvent } from '../utils/datadogRum';
import { trackWithFingerprint } from '../utils/trackWithFingerprint';

import Page from '../components/Page';

export default function NotFoundPage(data: PageProps) {
  useEffect(() => {
    const url = window.location.href;
    const referrer = url.substring(url.indexOf('#') + 1);
    trackWithFingerprint('404 Page Viewed', {
      url: window.location.href,
      referrer
    });
    trackRumEvent('404 Page Viewed', {
      url: window.location.href,
      referrer
    });
  }, []);

  return <Page {...data} />;
}

export async function getStaticProps() {
  const { globalConfig, pageData } = await getPageBySlug('404');

  return {
    props: {
      globalConfig: globalConfig ?? {},
      pageData: pageData ?? {}
    }
  };
}
